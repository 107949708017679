/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import Errors from '../components/Errors'
import Page from '../templates/page'

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return <Errors errors={errors} />
  }
  return <Page data={data} />
}

export default IndexPage

export const query = graphql`
  query FrontpageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
      navMenu {
        items {
          title
          kind
          buttonColor {
            hex
          }
          linkLabelColor {
            hex
          }
        }
      }
      seo {
        description
        seoTitle
        keywords
        openGraphImage {
          ...SanityImage
          alt
          caption
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      url
      useLogo
      logoColor {
        hex
      }
      seo {
        seoTitle
        description
        keywords
        openGraphImage {
          ...SanityImage
          alt
          caption
        }
      }
      footerNavigation {
        page {
          title
        }
        slug {
          current
        }
      }
      socialLinks {
        type
        link
      }
    }
  }
`
